import axios from 'axios'
import {IHostPropertyInput, IHostPropertyManualInput} from '../interfaces/Property'
import {Endpoints} from './Endpoints'
import {
  AcceptStaffInviteRequest,
  LoginRequest,
  ManagerCityPermission,
  RegisterRequest,
  StaffAddRequest,
  StaffOfferedRequest,
} from './PostRequestModels'
import {AddressRequest} from './PostRequestModels/AddressRequests'
import {IChangePasswordStaff} from './PostRequestModels/ChangePasswordRequest'
import {
  AssignChecklistRequest,
  ChecklistAddRequest,
  ChecklistEditRequest,
  ChecklistMainUpdateRequest,
  ChecklistQuestionEditRequest,
  ChecklistSectionAddRequest,
  ChecklistSectionEditRequest,
  ChecklistSetQuestionOrderRequest,
  ChecklistSetSectionOrderRequest,
} from './PostRequestModels/ChecklistRequests'
import {
  IJobCancelRequest,
  JobNoteRequest,
  JobReviewRequest,
  JobServiceAddRequest,
  JobTaskRequest,
  ManualJobRequest,
} from './PostRequestModels/JobRequests'
import {IPostOffDays} from './PostRequestModels/OffDaysRequest'
import {PaymentRequest} from './PostRequestModels/PaymentRequest'
import {BanRequest, PermissionRequest} from './PostRequestModels/Permission'
import {
  GuestyRequest,
  GuestyRequestV2,
  HostawayRequest,
  MewsRequest,
  MyVrRequest,
  StayntouchRequest,
} from './PostRequestModels/PropertyFromPMS'
import * as Sentry from '@sentry/react'
import {IStaffAddress} from '../interfaces/Address'
import {ForgotRequest} from './PostRequestModels/UserRequest'

var httpClient = axios.create({
  httpsAgent: {
    rejectUnauthorized: false,
  },
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

var httpClientFormData = axios.create({
  httpsAgent: {
    rejectUnauthorized: false,
  },
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
  },
})
httpClient.defaults.timeout = 500000
httpClientFormData.defaults.timeout = 500000

httpClient.interceptors.response.use(
  (res) => res,
  (err) => {
    try {
      if (err?.response?.data?.statusCode === 500) {
        Sentry.captureException({
          responseData: {...err?.response?.data},
          requestData: JSON.parse(err?.response?.config?.data),
          url: err?.response?.config?.url,
        })
      }
    } catch (interceptorError) {
      // Do not sent it to Sentry
    } finally {
      return Promise.reject(err)
    }
  }
)

httpClientFormData.interceptors.response.use(
  (res) => res,
  (err) => {
    try {
      if (err?.response?.data?.statusCode === 500) {
        Sentry.captureException({
          responseData: {...err?.response?.data},
          requestData: JSON.parse(err?.response?.config?.data),
          url: err?.response?.config?.url,
        })
      }
    } catch (interceptorError) {
      //Do not sent it to Sentry
    } finally {
      return Promise.reject(err)
    }
  }
)
interface IApiCalls {}

class ApiCalls implements IApiCalls {
  private server_link: string
  public token: string
  public logout: () => void

  constructor() {
    //this.server_link = 'https://turnify.azurewebsites.net/api/' //Production
    this.server_link = 'https://trnfyapi.nono.company/api/'
    //this.server_link = "https://turnify-turnify-staging.azurewebsites.net/api/" // Dev
    //this.server_link = "http://3.135.239.85/api/";        //LOCAL
    //this.server_link = 'http://localhost:5139/api/' //LOCAL
    //this.server_link = 'https://turnify-api-dev.nonoco.dev/api/' //Development

    this.token = 'not set'
    this.logout = () => {}
  }

  ressetToken = () => {
    const newHttpClient = axios.create({
      httpsAgent: {
        rejectUnauthorized: false,
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    newHttpClient.defaults.timeout = 500000

    httpClient = newHttpClient
  }

  setToken = (token: string) => {
    httpClient.defaults.headers['Authorization'] = 'Bearer ' + token
    httpClientFormData.defaults.headers['Authorization'] = 'Bearer ' + token
    httpClient.interceptors.response.use(
      (res) => res,
      (err) => {
        try {
          if (err?.response?.data?.statusCode === 401) {
            this.logout()
          }
          if (err?.response?.data?.statusCode === 500) {
            Sentry.captureException({
              responseData: {...err?.response?.data},
              requestData: JSON.parse(err?.response?.config?.data),
              url: err?.response?.config?.url,
            })
          }
        } catch (interceptorError) {
          //Do not sent it to Sentry
        } finally {
          return Promise.reject(err)
        }
      }
    )
    httpClientFormData.interceptors.response.use(
      (res) => res,
      (err) => {
        try {
          if (err?.response?.data?.statusCode === 401) {
            this.logout()
          }
          if (err?.response?.data?.statusCode === 500) {
            Sentry.captureException({
              responseData: {...err?.response?.data},
              requestData: JSON.parse(err?.response?.config?.data),
              url: err?.response?.config?.url,
            })
          }
        } catch (interceptorError) {
          //Do not sent it to Sentry
        } finally {
          return Promise.reject(err)
        }
      }
    )
  }

  setLogout = (func: () => void) => {
    this.logout = func
  }

  //Auth
  login = (loginRequest: LoginRequest) => {
    return httpClient.post(this.server_link + Endpoints.Users.Login, loginRequest)
  }

  register = (registerRequest: RegisterRequest) => {
    return httpClient.post(this.server_link + Endpoints.Users.Register, registerRequest)
  }

  newRegister = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Users.NewRegister, data)
  }

  verifyUser = (token: string | null) => {
    return httpClient.post(this.server_link + Endpoints.Users.ConfirmAccount, {token: token})
  }

  getUserDetails = () => {
    return httpClient.get(this.server_link + Endpoints.Users.GetUserDetails)
  }

  getUserDetailsById = (userId: string) => {
    return httpClient.get(this.server_link + Endpoints.Users.GetUserDetailsById + userId)
  }

  editUser = (data: any, userId: string) => {
    return httpClient.put(this.server_link + Endpoints.Users.EditUser + userId, data)
  }

  deleteUserAccount = (userId: number) => {
    return httpClient.delete(this.server_link + Endpoints.Users.DeleteUserAccount + userId)
  }

  //Hosts
  getHostById = (hostId: any) => {
    return httpClient.get(this.server_link + Endpoints.Host.GetHostById + hostId)
  }
  getForceFinishRequest = (hostId: any) => {
    return httpClient.get(this.server_link + Endpoints.Host.GetForceFinish + hostId)
  }
  postApproveForce = (hostId: number) => {
    return httpClient.post(
      this.server_link + Endpoints.JobForceFinishRequest.PostApproveForce + hostId
    )
  }
  postDeclineForce = (hostId: number) => {
    return httpClient.post(
      this.server_link + Endpoints.JobForceFinishRequest.PostDeclineForce + hostId
    )
  }

  getHostUsers = (hostId: any) => {
    return httpClient.get(this.server_link + Endpoints.Host.GetHostUsers + hostId)
  }

  postBillingInformation = () => {
    return httpClient.post(this.server_link + Endpoints.Host.PostBillingInformation)
  }
  getBillingInformation = () => {
    return httpClient.get(this.server_link + Endpoints.Billing.GetBillingInformation)
  }

  getStripeAccountLink = () => {
    return httpClient.get(this.server_link + Endpoints.Host.StripeAccountLink)
  }
  postCustomerSource = (data: {source: string}) => {
    return httpClient.post(this.server_link + Endpoints.Billing.PostCustomerSource, data)
  }
  putDefaultCustomerSource = (data: {source: string}) => {
    return httpClient.put(this.server_link + Endpoints.Billing.PutDefaultCustomerSource, data)
  }
  deleteCustomerSource = (sourceId: string) => {
    return httpClient.delete(this.server_link + Endpoints.Billing.DeleteCustomerSource + sourceId)
  }
  getCustomerSources = () => {
    return httpClient.get(this.server_link + Endpoints.Billing.GetPaymentSource)
  }
  checkCustomerHasCard = () => {
    return httpClient.get(this.server_link + Endpoints.Billing.CheckHostHasCard)
  }

  getICalPmsInfo = (hostId: number) => {
    return httpClient.get(this.server_link + Endpoints.Host.ICalPmsInfo + hostId)
  }

  //Account Types
  getAccountStatusTypes = () => {
    return httpClient.get(this.server_link + Endpoints.Account.GetAccountStatusType)
  }
  //Jobs
  todayJobSummary = (hostId: number) => {
    return httpClient.get(this.server_link + Endpoints.Job.TodayJobSummary + hostId)
  }
  weeklyJobs = (hostId: number) => {
    return httpClient.get(this.server_link + Endpoints.Job.WeeklyJobs + hostId)
  }
  todayJobSummaryByProperty = (hostPropertyId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Job.TodayJobSummaryByProperty + hostPropertyId
    )
  }
  weeklyJobsByProperty = (hostPropertyId: number) => {
    return httpClient.get(this.server_link + Endpoints.Job.WeeklyJobsByProperty + hostPropertyId)
  }
  getJobsByStatusId = (
    hostId: number,
    jobStatusId: number,
    cleaningPeriodStart: any,
    cleaningPeriodEnd: any
  ) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Job.GetJobsByStaffId +
        hostId +
        '&jobStatusTypeId=' +
        jobStatusId +
        '&cleaningPeriodStart=' +
        cleaningPeriodStart +
        '&cleaningPeriodEnd=' +
        cleaningPeriodEnd
    )
  }
  getIsLateStartJobs = (lateCase: boolean) => {
    return httpClient.get(
      this.server_link + Endpoints.Job.GetLateStartJobs + lateCase + '&isLive=true'
    )
  }
  getIsLateFinishJobs = (lateCase: boolean) => {
    return httpClient.get(
      this.server_link + Endpoints.Job.GetLateFinishJobs + lateCase + '&isLive=true'
    )
  }
  getLiveJobs = (hostId: number, localTime: string) => {
    return httpClient.get(
      this.server_link + Endpoints.Job.GetLiveJobs + '?hostId=' + hostId + '&localTime=' + localTime
    )
  }

  getJobsByStaffId = (hostId: number) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobsByStaffId + hostId)
  }

  getJobsByUserId = (userId: number | undefined, params?: any) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobsByUserAndRoleId + userId + params)
  }

  getStaffJobsByHost = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Job.GetStaffJobsByHost, data)
  }

  getJobsByVendorId = (vendorId: number | undefined) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobsByVendorId + vendorId)
  }

  getJobsByPropertyId = (
    hostPropertyId: number,
    cleaningPeriodStart: any,
    cleaningPeriodEnd: any
  ) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Job.GetJobsByPropertyId +
        hostPropertyId +
        '&cleaningPeriodStart=' +
        cleaningPeriodStart +
        '&cleaningPeriodEnd=' +
        cleaningPeriodEnd
    )
  }
  getAllJobIssueByProperty = (propertyId: number) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetAllIssueByPropertyId + propertyId)
  }

  addJob = (data: ManualJobRequest) => {
    return httpClient.post(this.server_link + Endpoints.Job.AddJob, data)
  }

  getJobServices = (jobId: any) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobServices + jobId)
  }

  getJobDetails = (jobId: any) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobDetails + jobId)
  }

  sendVendorScore = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Job.SendVendorScore, data)
  }

  addJobService = (data: JobServiceAddRequest) => {
    return httpClient.post(this.server_link + Endpoints.Job.AddJobService, data)
  }

  deleteJobService = (serviceId: any) => {
    return httpClient.delete(this.server_link + Endpoints.Job.DeleteJobService + serviceId)
  }

  getJobNotes = (jobId: any) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobNotes + jobId)
  }

  addJobNote = (data: JobNoteRequest) => {
    return httpClient.post(this.server_link + Endpoints.Job.AddJobNote, data)
  }

  getJobTasks = (jobId: any) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobTasks + jobId)
  }

  getJobStaffs = (jobId: any) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobStaffs + jobId)
  }

  addJobTasks = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Job.AddJobTask, data)
  }

  deleteJobNote = (noteId: any) => {
    return httpClient.delete(this.server_link + Endpoints.Job.DeleteJobNote + noteId)
  }

  updateJobTask = (jobTaskId: any, data: any) => {
    return httpClient.put(this.server_link + Endpoints.Job.UpdateJobTask + jobTaskId, data)
  }

  deleteJobTask = (jobTaskId: any) => {
    return httpClient.delete(this.server_link + Endpoints.Job.DeleteJobTask + jobTaskId)
  }

  getJobIssues = (jobId: any) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobIssues + jobId)
  }

  resolveIssue = (jobIssueId: number) => {
    return httpClient.put(this.server_link + Endpoints.Job.ResolveIssue + jobIssueId)
  }

  declineIssue = (jobIssueId: number) => {
    return httpClient.put(this.server_link + Endpoints.Job.DeclineIssue + jobIssueId)
  }

  addJobIssue = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Job.AddJobIssue, data)
  }

  updateJobIssue = (jobIssueId: any, data: any) => {
    return httpClient.put(this.server_link + Endpoints.Job.UpdateJobIssue + jobIssueId, data)
  }

  deleteJobIssue = (jobTaskId: any) => {
    return httpClient.delete(this.server_link + Endpoints.Job.DeleteJobIssue + jobTaskId)
  }

  getChecklistPhotos = (jobId: any) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetChecklistPhotos + jobId)
  }

  getJobReview = (jobId: any, serviceTypeId: any) => {
    return httpClient.get(
      this.server_link + Endpoints.Job.GetJobReview + jobId + '&serviceTypeId=' + serviceTypeId
    )
  }

  addJobReview = (data: JobReviewRequest) => {
    return httpClient.post(this.server_link + Endpoints.Job.AddJobReview, data)
  }

  sendJobReview = (data: JobReviewRequest) => {
    return httpClient.post(this.server_link + Endpoints.Job.SendJobReview, data)
  }

  deleteJobReview = (reviewId: number) => {
    return httpClient.delete(this.server_link + Endpoints.Job.DeleteReview + reviewId)
  }

  cancelJob = (data: IJobCancelRequest) => {
    return httpClient.put(this.server_link + Endpoints.Job.CancelJob + data.jobId + '/cancel', data)
  }

  putForceFinish = (jobId: any) => {
    return httpClient.put(this.server_link + Endpoints.Job.jobForceFinish + jobId)
  }
  getJobCancelTypes = () => {
    return httpClient.get(this.server_link + Endpoints.JobCancelTypes.GetJobCancelTypes)
  }
  getPropertyRelatedJobs = (hostPropertyId: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Job.AddJob +
        '/web?hostPropertyId=' +
        hostPropertyId +
        '&jobStatusTypeId=1' +
        '&jobStatusTypeId=2' +
        '&jobStatusTypeId=3' +
        '&jobStatusTypeId=4'
    )
  }
  getOffDayEffectedJob = (userId: number, offDay: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Job.GetAllJobs +
        '?userId=' +
        userId +
        '&jobStatusTypeId=2&offDay=' +
        offDay
    )
  }

  //Turnovers
  getFutureTurnovers = (hostId: number) => {
    return httpClient.get(this.server_link + Endpoints.Turnovers.GetFutureTurnovers + hostId)
  }

  getPastTurnovers = (hostId: number) => {
    return httpClient.get(this.server_link + Endpoints.Turnovers.GetPastTurnovers + hostId)
  }

  //Marketplace
  getMarketplaceRequest = (hostId: number) => {
    return httpClient.get(this.server_link + Endpoints.Marketplace.GetRequests + hostId)
  }

  createMarketplaceRequest = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Marketplace.CreateNewRequest, data)
  }

  getMarketplaceDetail = (marketplaceId: number) => {
    return httpClient.get(this.server_link + Endpoints.Marketplace.GetDetail + marketplaceId)
  }

  checkPropertyMarketplace = (hostPropertyId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Marketplace.CheckPropertyMarketplace + hostPropertyId
    )
  }

  updateMarketplaceRequestStatus = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Marketplace.UpdateRequest, data)
  }

  acceptOrDeclineMarketplaceRequest = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Marketplace.AcceptDeclineOffer, data)
  }

  createMarketplaceJob = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Marketplace.CreateJob, data)
  }

  getPropertyOffersByService = (data: any) => {
    return httpClient.post(
      this.server_link + Endpoints.Marketplace.GetPropertyOffersByService,
      data
    )
  }

  getMarketplaceUsersByPropertyId = (hostPropertyId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Staff.GetMarketplaceStaffsByPropertyId + hostPropertyId
    )
  }

  //PMS
  getPMSTypes = () => {
    return httpClient.get(this.server_link + Endpoints.PMS.GetPMSTypes)
  }

  getPMSKeysByHostId = (hostId: any, pmsTypeId: any) => {
    return httpClient.get(
      this.server_link + Endpoints.PMS.GetPMSKeys + hostId + '&pmsTypeId=' + pmsTypeId
    )
  }

  // getPropertiesFromGuesty = (data: GuestyRequest) => {
  //   return httpClient.post(this.server_link + Endpoints.Property.GetPropertiesFromGuesty, data)
  // }

  getPropertiesFromGuesty = (data: GuestyRequestV2) => {
    return httpClient.post(this.server_link + Endpoints.Property.GetPropertiesFromGuesty, data)
  }

  getPropertiesFromHostify = (data: HostawayRequest) => {
    return httpClient.post(this.server_link + Endpoints.Property.GetPropertiesFromHostify, data)
  }

  getPropertiesFromStayntouch = (data: StayntouchRequest) => {
    return httpClient.post(this.server_link + Endpoints.Property.GetPropertiesFromStayntouch, data)
  }

  getPropertiesFromMyVr = (data: MyVrRequest) => {
    return httpClient.post(this.server_link + Endpoints.Property.GetPropertiesFromMyVr, data)
  }

  getPropertiesFromMews = (data: MewsRequest) => {
    return httpClient.post(this.server_link + Endpoints.Property.GetPropertiesFromMews, data)
  }

  //NEW REGISTER PMS APIS
  getGuestyProperties = (data: GuestyRequestV2) => {
    return httpClient.post(this.server_link + Endpoints.Property.GetGuestyProperties, data)
  }

  getHostawayProperties = (data: HostawayRequest) => {
    return httpClient.post(this.server_link + Endpoints.Property.GetHostawayProperties, data)
  }

  getStayntouchProperties = (data: StayntouchRequest) => {
    return httpClient.post(this.server_link + Endpoints.Property.GetStayntouchProperties, data)
  }

  getMyVrProperties = (data: MyVrRequest) => {
    return httpClient.post(this.server_link + Endpoints.Property.GetMyVrProperties, data)
  }

  putPropertyPmsReference = (data: {pmsReferenceId: string}, propertyId: any) => {
    return httpClient.put(
      this.server_link + Endpoints.Property.PutPropertyPmsReference + propertyId,
      data
    )
  }

  postHostPropertyList = (data: IHostPropertyInput[]) => {
    return httpClient.post(this.server_link + Endpoints.Property.PostHostProperties, data)
  }

  getHostProperties = (hostId: any) => {
    return httpClient.get(this.server_link + Endpoints.Property.GetProperties + hostId)
  }

  getHostPropertyFutureJobSummary = (hostPropertyId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Job.GetPropertyFutureJobSummary + hostPropertyId
    )
  }

  syncPropertyCalendar = (hostPropertyId: number) => {
    return httpClient.get(this.server_link + Endpoints.Property.SyncCalendar + hostPropertyId)
  }

  updatePropertyName = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Property.UpdateHostPropertyName, data)
  }

  getHostPropertiesByProvider = (hostId: any, cleaningProviderId: any, isMarketplace: boolean) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Property.GetProperties +
        hostId +
        '&cleaningProviderId=' +
        cleaningProviderId +
        '&isMarketplace=' +
        isMarketplace
    )
  }

  getHostPropertiesByStatus = (hostId: number, statusId: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Property.GetProperties +
        hostId +
        '&hostPropertyStatusTypeId=' +
        statusId
    )
  }

  postHostProperty = (data: IHostPropertyInput) => {
    return httpClient.post(this.server_link + Endpoints.Property.PostHostProperty, data)
  }

  //Developed at 2022. Add Property Function
  createHostProperty = (data: IHostPropertyManualInput) => {
    return httpClient.post(this.server_link + Endpoints.Property.CreateHostProperty, data)
  }

  //Developed at 2023. Add Property Function
  createNewHostProperty = (data: IHostPropertyManualInput) => {
    return httpClient.post(this.server_link + Endpoints.Property.CreateNewHostProperty, data)
  }

  updateHostProperty = (hostPropertyId: number, data: IHostPropertyInput) => {
    return httpClient.put(
      this.server_link + Endpoints.Property.UpdateHostProperty + hostPropertyId,
      data
    )
  }

  updateHostPropertyPayment = (hostPropertyId: number, data: IHostPropertyManualInput) => {
    return httpClient.put(
      this.server_link + Endpoints.Property.UpdateHostPropertyPaymentInfo + hostPropertyId,
      data
    )
  }

  getHostPropertyOtherQuestion = () => {
    return httpClient.get(this.server_link + Endpoints.Property.HostPropertyOtherQuestion)
  }

  getHostPropertyById = (propertyId?: number) => {
    return httpClient.get(this.server_link + Endpoints.Property.GetHostProperty + propertyId)
  }

  getAllowedProperties = (staffId: any) => {
    return httpClient.get(this.server_link + Endpoints.Property.AllowedProperties + staffId)
  }
  putPrirorityProperty = (data: any) => {
    return httpClient.put(this.server_link + Endpoints.Property.PutPriorityProperty, data)
  }
  updatePropertyUserPriorityByUser = (data: any) => {
    return httpClient.post(
      this.server_link + Endpoints.Property.UpdatePropertyUserPriorityByUser,
      data
    )
  }

  getExcludedStaffByPropertyId = (propertyId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Property.GetExcludedStaff + propertyId + '/users'
    )
  }
  deleteExcludeStaff = (excludeId: number) => {
    return httpClient.delete(this.server_link + Endpoints.Property.DeleteExcludedStaff + excludeId)
  }
  getBannedProperties = (userId: any) => {
    return httpClient.get(
      this.server_link + Endpoints.Property.BannedProperties + userId + '/properties'
    )
  }

  getAcceptedProperties = (hostPropertyId: any) => {
    return httpClient.get(this.server_link + Endpoints.Property.AcceptedProperties + hostPropertyId)
  }

  getAcceptedMyProperties = (hostPropertyId: any) => {
    return httpClient.get(
      this.server_link + Endpoints.Property.AcceptedMyProperties + hostPropertyId
    )
  }

  addPropertyPermission = (data: PermissionRequest[]) => {
    return httpClient.post(this.server_link + Endpoints.Property.AddPropertyPermission, data)
  }

  banUserFromProperty = (data: BanRequest) => {
    return httpClient.post(this.server_link + Endpoints.Property.BanUserFromProperty, data)
  }

  deletePropertyPermission = (permissionId: any) => {
    return httpClient.delete(
      this.server_link + Endpoints.Property.DeletePropertyPermission + permissionId
    )
  }

  deletePropertyPermissionByUser = (data: any) => {
    return httpClient.post(
      this.server_link + Endpoints.Property.DeletePropertyPermissionByUser,
      data
    )
  }

  deleteBanUserFromProperty = (permissionId: any) => {
    return httpClient.delete(
      this.server_link + Endpoints.Property.DeleteBanUserFromProperty + permissionId
    )
  }

  getHostPropertyServiceOffers = (hostPropertyId: number | string | undefined) => {
    return httpClient.get(
      this.server_link + Endpoints.Property.GetHostPropertyServiceOffers + hostPropertyId
    )
  }

  getHostPropertyMarketplaceOffers = (data: any) => {
    return httpClient.post(
      this.server_link + Endpoints.Property.GetHostPropertyMarketplaceOffers,
      data
    )
  }

  editPropertyStatus = (propertyId: any, statusId: number) => {
    return httpClient.put(
      this.server_link + Endpoints.Property.EditPropertyStatus + propertyId + '/edit-status',
      {hostPropertyId: parseInt(propertyId), hostPropertyStatusTypeId: statusId}
    )
  }
  editHostProperty = (hostPropertyId: number, data: any) => {
    return httpClient.get(
      this.server_link + Endpoints.Property.PutHostProperty + hostPropertyId,
      data
    )
  }
  requestTurnifyPro = (hostPropertyId: number) => {
    return httpClient.put(this.server_link + Endpoints.Property.GetOffer(hostPropertyId.toString()))
  }

  //Countries
  getCountries = () => {
    return httpClient.get(this.server_link + Endpoints.Countries.GetCountries)
  }

  //Cleaning Providers
  getCleaningProviders = () => {
    return httpClient.get(this.server_link + Endpoints.CleaningProviders.GetCleaningProviders)
  }

  //Access Types
  getAccessTypes = () => {
    return httpClient.get(this.server_link + Endpoints.AccessTypes.GetAccessTypes)
  }

  //Checklists
  getMasterChecklists = (hostId: any) => {
    return httpClient.get(
      this.server_link + Endpoints.Checklist.GetMasterChecklists + '?hostId=' + hostId
    )
  }
  getHostMasterChecklists = (hostId: any) => {
    return httpClient.get(
      this.server_link + Endpoints.Checklist.GetHostMasterChecklists + '?hostId=' + hostId
    )
  }
  getHostChecklists = (hostId: any) => {
    return httpClient.get(this.server_link + Endpoints.Checklist.GetHostChecklists + hostId)
  }
  getHostMasterChecklistsByService = (hostId: any, serviceTypeId: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Checklist.GetHostMasterChecklists +
        '?hostId=' +
        hostId +
        '&serviceTypeId=' +
        serviceTypeId
    )
  }
  getMasterChecklistsByService = (hostId: any, serviceTypeId: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Checklist.GetMasterChecklists +
        '?hostId=' +
        hostId +
        '&serviceTypeId=' +
        serviceTypeId
    )
  }
  getAdminMasterChecklists = () => {
    return httpClient.get(this.server_link + Endpoints.Checklist.GetMasterChecklists)
  }

  getHostPropertyChecklists = (hostPropertyId?: number, roleId?: any, serviceTypeId?: any) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Checklist.GetHostPropertyChecklists +
        hostPropertyId +
        (serviceTypeId ? `&serviceTypeId=${serviceTypeId}` : '') +
        (roleId ? `&roleId=${roleId}` : '')
    )
  }

  getHostPropertyChecklistsByService = (
    hostPropertyId?: number,
    hostId?: any,
    serviceTypeId?: any
  ) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Checklist.GetHostPropertyChecklistsByService +
        '?hostPropertyId=' +
        hostPropertyId +
        '&hostId=' +
        hostId +
        '&serviceTypeId=' +
        serviceTypeId
    )
  }

  postHostPropertyChecklist = (data: ChecklistAddRequest) => {
    return httpClient.post(this.server_link + Endpoints.Checklist.PostHostPropertyChecklist, data)
  }

  editChecklist = (data: ChecklistEditRequest, hostPropertyChecklistId?: number) => {
    return httpClient.put(
      this.server_link + Endpoints.Checklist.EditChecklist + hostPropertyChecklistId,
      data
    )
  }

  updateChecklistMainInfo = (
    data: ChecklistMainUpdateRequest,
    hostPropertyChecklistId?: number
  ) => {
    return httpClient.put(
      this.server_link + Endpoints.Checklist.UpdateMainChecklist + hostPropertyChecklistId,
      data
    )
  }

  getChecklistItems = (checklistId: number) => {
    return httpClient.get(this.server_link + Endpoints.Checklist.GetChecklistItems + checklistId)
  }

  addChecklistSection = (data: ChecklistSectionAddRequest) => {
    return httpClient.post(this.server_link + Endpoints.Checklist.AddChecklistSection, data)
  }

  getChecklistSectionTypes = () => {
    return httpClient.get(this.server_link + Endpoints.Checklist.GetChecklistSectionTypes)
  }

  setSectionOrder = (data: ChecklistSetSectionOrderRequest[], checklistId?: number) => {
    return httpClient.put(
      this.server_link + Endpoints.Checklist.SetSectionOrder + checklistId + '/sections/order',
      data
    )
  }

  setQuestionOrder = (data: ChecklistSetQuestionOrderRequest[], sectionId?: number) => {
    return httpClient.put(
      this.server_link + Endpoints.Checklist.SetSectionOrder + sectionId + '/questions/order',
      data
    )
  }

  editSection = (data: ChecklistSectionEditRequest, sectionId?: number) => {
    return httpClient.put(this.server_link + Endpoints.Checklist.EditSection + sectionId, data)
  }

  editQuestion = (data: ChecklistQuestionEditRequest, questionId?: number) => {
    return httpClient.put(this.server_link + Endpoints.Checklist.EditQuestion + questionId, data)
  }

  addQuestion = (data: any) => {
    return httpClientFormData.post(this.server_link + Endpoints.Checklist.AddQuestion, data)
  }

  editQuestionPhoto = (data: any, samplePhotoId: any) => {
    return httpClientFormData.put(
      this.server_link + Endpoints.Checklist.EditQuestionPhoto + samplePhotoId,
      data
    )
  }

  newQuestionPhoto = (data: any) => {
    return httpClientFormData.post(this.server_link + Endpoints.Checklist.NewQuestionPhoto, data)
  }

  deleteChecklist = (checklistId: any) => {
    return httpClient.delete(this.server_link + Endpoints.Checklist.DeleteChecklist + checklistId)
  }

  assignChecklists = (data: AssignChecklistRequest) => {
    return httpClient.post(this.server_link + Endpoints.Checklist.AssignChecklists, data)
  }
  deleteSamplePhoto = (photoId: any) => {
    return httpClient.delete(this.server_link + Endpoints.Checklist.DeleteSamplePhoto + photoId)
  }

  deleteChecklistSection = (sectionId?: number) => {
    return httpClient.delete(this.server_link + Endpoints.Checklist.DeleteSection + sectionId)
  }

  deleteSectionQuestion = (questionId?: number) => {
    return httpClient.delete(this.server_link + Endpoints.Checklist.DeleteQuestion + questionId)
  }
  cloneSection = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Checklist.PostCloneSection, data)
  }
  cloneSectionQuestion = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Checklist.PostCloneSectionQuestion, data)
  }
  postCloneCheckLists = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Checklist.PostCloneChecklist, data)
  }

  updateFutureJobsChecklistsByProperty = (data: any) => {
    return httpClient.put(
      this.server_link + Endpoints.Checklist.UpdateFutureJobsChecklistsByProperty,
      data
    )
  }
  //Pro Team
  getProTeam = (hostId: number, roleId?: number) => {
    return httpClient.get(this.server_link + Endpoints.ProTeam.GetProTeamList + hostId)
  }

  getProTeamDetails = (userId: any) => {
    return httpClient.post(this.server_link + Endpoints.ProTeam.GetProTeamDetails, {userId: userId})
  }

  getProTeamUserAllowedProperties = (userId: any) => {
    return httpClient.post(this.server_link + Endpoints.ProTeam.GetProTeamUserProperties, {
      userId: userId,
    })
  }

  //Staff
  getStaff = (hostId: number, roleId?: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Staff.GetStaffs + hostId + (roleId ? `&roleId=` + roleId : '')
    )
  }

  getHostStaffs = (hostId: number) => {
    return httpClient.get(this.server_link + Endpoints.Staff.GetHostStaffs + hostId)
  }

  getHostStaffByProperty = (hostPropertyId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Staff.GetHostStaffByProperty + hostPropertyId
    )
  }

  getStaffByPropertyId = (hostId: number, hostPropertyId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Staff.GetStaffByPropertyId(hostId) + hostPropertyId
    )
  }

  getVendorUsersByPropertyId = (hostPropertyId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Staff.GetVendorStaffsByPropertyId + hostPropertyId
    )
  }

  getStaffByVendorId = (vendorId: number | undefined) => {
    return httpClient.get(
      this.server_link + Endpoints.Staff.GetStaffByVendorId + vendorId + '&accountStatusId=' + 1
    )
  }

  createStaff = (data: StaffAddRequest) => {
    return httpClient.post(this.server_link + Endpoints.Staff.CreateStaff, data)
  }

  getStaffDetails = (staffId: any) => {
    return httpClient.get(this.server_link + Endpoints.Staff.GetStaffDetails + staffId)
  }

  addStaffOfferedService = (data: StaffOfferedRequest) => {
    return httpClient.post(this.server_link + Endpoints.Staff.AddStaffOfferedService, data)
  }

  updateStaffOfferedService = (offeredServiceId: any, data: StaffOfferedRequest) => {
    return httpClient.put(
      this.server_link + Endpoints.Staff.UpdateStaffOfferedService + offeredServiceId,
      data
    )
  }

  updateStaffServiceRate = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Staff.UpdateStaffServiceRate, data)
  }

  getStaffEmailNotVerified = (hostId: number | undefined, accountStatusId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Staff.GetStaffs + hostId + '&accountStatusId=' + accountStatusId
    )
  }
  deleteStaffOfferedService = (offeredServiceId: any) => {
    return httpClient.delete(
      this.server_link + Endpoints.Staff.DeleteStaffOfferedservice + offeredServiceId
    )
  }

  acceptInvite = (data: AcceptStaffInviteRequest) => {
    return httpClient.post(this.server_link + Endpoints.Staff.AcceptInvite, data)
  }

  forgotPassword = (data: ForgotRequest) => {
    return httpClient.post(this.server_link + Endpoints.Staff.Forgotpassword, data)
  }
  resetPassword = (data: AcceptStaffInviteRequest) => {
    return httpClient.post(this.server_link + Endpoints.Staff.ResetPassword, data)
  }
  putStaffPassword = (data: IChangePasswordStaff, staffId: number) => {
    return httpClient.put(this.server_link + Endpoints.Staff.StaffChangePassword + staffId, data)
  }

  getHostUserGroups = () => {
    return httpClient.get(this.server_link + Endpoints.Staff.GetHostUserGroups)
  }

  createHostUserGroups = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Staff.CreateHostUserGroup, data)
  }

  updateHostUserGroups = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Staff.UpdateHostUserGroup, data)
  }

  deleteHostUserGroup = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Staff.DeleteHostUserGroup, data)
  }

  addUserToHostUserGroup = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Staff.AddUserToHostUserGroup, data)
  }

  blockStaff = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Staff.BlockStaff, data)
  }

  getStaffServicesWithRates = (userId: number) => {
    return httpClient.get(this.server_link + Endpoints.Staff.GetStaffServicesWithRates + userId)
  }

  //Manager
  getManagerProperties = (userId: number) => {
    return httpClient.get(this.server_link + Endpoints.Manager.GetManagerProperties + userId)
  }
  getAcceptedManagerProperties = (userId: number) => {
    return httpClient.get(this.server_link + Endpoints.Manager.GetAcceptedProperties + userId)
  }
  postManagerProperties = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Manager.PostManagerProperties, data)
  }
  deleteManagerProperties = (data: any) => {
    return httpClient.delete(this.server_link + Endpoints.Manager.DeleteManagerProperties, {data})
  }
  getManagerCities = (userId: number, hostId: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Manager.GetManagerCities +
        '?userId=' +
        userId +
        '&hostId=' +
        hostId
    )
  }
  postManagerCities = (data: ManagerCityPermission) => {
    return httpClient.post(this.server_link + Endpoints.Manager.PostManagerCities, data)
  }
  deleteManagerCities = (cityId: number) => {
    return httpClient.delete(this.server_link + Endpoints.Manager.DeleteManagerCities + cityId)
  }

  //Status
  getStaffStatusTypes = () => {
    return httpClient.get(this.server_link + Endpoints.Staff.StaffStatusTypes)
  }
  updateStaffStatus = (data: any, userId: number) => {
    return httpClient.put(this.server_link + Endpoints.Staff.UpdateStaffStatus + '/' + userId, data)
  }

  //Roles
  getStaffRoles = () => {
    return httpClient.get(this.server_link + Endpoints.Roles.GetStaffRoles)
  }

  //Service
  getServiceTypes = () => {
    return httpClient.get(this.server_link + Endpoints.Service.GetServiceTypes)
  }
  getServiceGroupsWithServices = () => {
    return httpClient.get(this.server_link + Endpoints.Service.GetServiceGroupsWithServices)
  }

  //Answer Types
  getChecklistAnswerTypes = () => {
    return httpClient.get(this.server_link + Endpoints.ChecklistAnswerTypes.GetChecklistAnswerTypes)
  }

  //Languages
  getLanguages = () => {
    return httpClient.get(this.server_link + Endpoints.Languages.GetLanguages)
  }

  //Address
  getAddressesByUserId = (userId: any) => {
    return httpClient.get(this.server_link + Endpoints.Address.GetAddressByUserId + userId)
  }

  createAddress = (userId: any, data: AddressRequest | IStaffAddress) => {
    return httpClient.post(
      this.server_link + Endpoints.Address.CreateAddress + userId + '/address',
      data
    )
  }

  updateAddress = (userId: any, addressId: any, data: AddressRequest | IStaffAddress) => {
    return httpClient.put(
      this.server_link + Endpoints.Address.UpdateAddress + userId + '/address/' + addressId,
      data
    )
  }

  deleteAddress = (addressId: any) => {
    return httpClient.delete(this.server_link + Endpoints.Address.DeleteAddress + addressId)
  }

  getAddressTypes = () => {
    return httpClient.get(this.server_link + Endpoints.Address.GetAddressTypes)
  }

  //Offered Services
  getOfferedServices = (userId: any) => {
    return httpClient.get(this.server_link + Endpoints.OfferedServices.GetOfferedServices + userId)
  }

  getOfferedServicesByHostId = (hostId: any) => {
    return httpClient.get(
      this.server_link + Endpoints.OfferedServices.GetOfferedServicesByHostId + hostId
    )
  }

  getOfferedStaffs = (
    hostId: any,
    hostPropertyId: any,
    serviceTypeId: any,
    roleId: any,
    scheduledStart: any
  ) => {
    return httpClient.get(
      this.server_link +
        Endpoints.OfferedServices.GetOfferedStaffs +
        serviceTypeId +
        '&roleId=' +
        roleId +
        '&hostId=' +
        hostId +
        '&hostPropertyId=' +
        hostPropertyId +
        '&scheduledStart=' +
        scheduledStart
    )
  }

  //Off Days
  getStaffOffDays = (staffId: any, roleId?: any) => {
    return httpClient.get(
      this.server_link + Endpoints.OffDays.GetStaffOffDays + staffId + '&roleId=' + roleId
    )
  }
  postStaffOffDays = (data: IPostOffDays) => {
    return httpClient.post(this.server_link + Endpoints.OffDays.PostStaffOffDays, data)
  }
  deleteStaffOffDays = (offDaysId: number) => {
    return httpClient.delete(
      this.server_link + Endpoints.OffDays.DeleteStaffOffDays + '/' + offDaysId
    )
  }

  getStaffOffTime = (staffId: any, roleId?: any) => {
    return httpClient.get(
      this.server_link + Endpoints.OffDays.GetStaffOffTime + staffId + '&roleId=' + roleId
    )
  }
  postStaffOffTime = (data: any, roleId?: any) => {
    return httpClient.post(this.server_link + Endpoints.OffDays.PostStaffOffTime, data)
  }
  deleteStaffOffTime = (offTimeId: any, roleId?: any) => {
    return httpClient.delete(this.server_link + Endpoints.OffDays.PostStaffOffTime + offTimeId)
  }

  //Rate Types
  getRateTypes = () => {
    return httpClient.get(this.server_link + Endpoints.JobRatingTypes.GetJobRatingTypes)
  }

  //Billings
  getAllBillings = () => {
    return httpClient.get(this.server_link + Endpoints.Billing.GetAllBillings)
  }

  getBillingsByHostId = (hostId: number | string) => {
    return httpClient.get(this.server_link + Endpoints.Billing.GetBillingsByHostId + hostId)
  }

  getBillingsByJobId = (jobId: number | string) => {
    return httpClient.get(this.server_link + Endpoints.Billing.GetBillingsByHostId + jobId)
  }

  getBillingDetails = (billingId: number | string) => {
    return httpClient.get(this.server_link + Endpoints.Billing.GetBillingDetail + billingId)
  }
  postInvoiceCharge = (invoiceId: number) => {
    return httpClient.post(
      this.server_link + Endpoints.Billing.PostInvoiceCharge + invoiceId + '/charge'
    )
  }

  verifyBilling = () => {
    return httpClient.post(this.server_link + Endpoints.Billing.VerifyBilling, {
      isVerifiedStripeAccount: true,
    })
  }

  disabledBilling = () => {
    return httpClient.post(this.server_link + Endpoints.Billing.VerifyBilling, {
      isVerifiedStripeAccount: false,
    })
  }

  disableStripe = () => {
    return httpClient.delete(this.server_link + Endpoints.Billing.DisableStripe)
  }

  //Search
  getSearch = (searchText: string | null) => {
    return httpClient.get(this.server_link + Endpoints.Search.GetSearch + searchText)
  }

  //Update Notes
  updateJobNote = (noteId: string | number, note: string) => {
    return httpClient.put(this.server_link + Endpoints.Job.UpdateJobNote + noteId, {note})
  }

  updateJobServiceNote = (noteId: string | number, note: string) => {
    return httpClient.put(this.server_link + Endpoints.Job.UpdateJobServiceNote + noteId, {note})
  }

  updateJobTaskNote = (noteId: string | number, note: string) => {
    return httpClient.put(this.server_link + Endpoints.Job.UpdateJobTaskNote + noteId, {note})
  }

  //ICal Validation
  validateICal = (link: string) => {
    return httpClient.post(this.server_link + Endpoints.ICalValidation.ValidateICall, {link})
  }

  //On boarding
  getOnBoarding = (hostId?: number) => {
    return httpClient.get(this.server_link + Endpoints.OnBoarding.GetOnBoarding + hostId)
  }

  //Notification
  getNotifications = () => {
    return httpClient.get(this.server_link + Endpoints.Notification.GetNotifications)
  }

  readNotification = (data: number[]) => {
    return httpClient.put(this.server_link + Endpoints.Notification.ReadNotification, {
      notificationIds: [...data],
    })
  }
  // Map
  getStates = () => {
    return httpClient.get(this.server_link + Endpoints.Map.GetStates)
  }
  getCities = (stateId: any) => {
    return httpClient.get(this.server_link + Endpoints.Map.GetCities + '?stateId=' + stateId)
  }

  //Get Failed Payments
  getFailedPayments = () => {
    return httpClient.post(this.server_link + Endpoints.Payment.GetFailedPayments)
  }

  //Make Payment
  makePayment = (paymentId: any) => {
    return httpClient.post(this.server_link + Endpoints.Payment.MakePayment + paymentId)
  }

  //Feeds
  getHostFeedSettings = (hostId: number) => {
    return httpClient.get(this.server_link + Endpoints.Feeds.GetHostFeedSettings + hostId)
  }

  updateHostFeedSettings = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Feeds.UpdateHostFeedSettings, data)
  }

  getHostFeeds = (hostId: number, skip: number, take: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Feeds.GetHostFeeds + hostId + '?skip=' + skip + '&take=' + take
    )
  }
  getHostPropertyFeeds = (hostPropertyId: number, skip: number, take: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Feeds.GetHostPropertyFeeds +
        hostPropertyId +
        '?skip=' +
        skip +
        '&take=' +
        take
    )
  }

  //Service Rates
  getHostPropertyServiceRates = (hostPropertyId: number | string | undefined) => {
    return httpClient.get(
      this.server_link + Endpoints.Property.GetHostPropertyServiceRates + hostPropertyId
    )
  }

  //New API's
  addPersonalPropertyManager = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Property.AddPersonalPropertyManager, data)
  }

  removePersonalPropertyManager = (data: any) => {
    return httpClient.post(
      this.server_link + Endpoints.Property.RemovePersonalPropertyManager,
      data
    )
  }
  updatePropertyToMarketplace = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Property.UpdatePropertyToMarketplace, data)
  }

  getSubscriptionInfo = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Property.GetSubscriptionInfo, data)
  }

  cancelSubscription = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Property.CancelSubscription, data)
  }
}

export default new ApiCalls()
